import WindowManager from '@core/scripts/components/window-manager';
import JshModule from '@core/scripts/helper/jsh-module';

const functionID = getFunctionId();

do {
  const {moduleScope} = JshModule('teaser/function-id');

  moduleScope.addEventListener('click', () => {
    WindowManager.setSpecificTabDataItem("pointOfSaleId", functionID);
  });

} while (document.querySelector('[jsh-module="teaser/function-id"]') !== null);

function getFunctionId() {
  const contentList = document.getElementsByClassName('content-list');
  return contentList[0].getAttribute(`jsh-tracking__functionId`);
}
